import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { planPrice } from 'common/utils/planPrice'

const defaultStrings = {
  mailHandling:
    '<span class="d-block">Business address</span> <span class="d-block">and Mail handling</span>',
  mailHandlingDescription: `<p class="text-semibold">We will handle all your post and notify you when it is ready to collect, 
    has been scanned or posted to you.</p>
    
  <p class="mt-sm-5 my-3 mb-sm-4">Just ${planPrice.monthly['virtual-office-scan-lite']} per month for our popular <a href="https://www.hoxtonmix.com/virtual-office-scan-lite/">Virtual Office Scan Lite</a> plan: scan, email, 30-day storage & shredding within one business day. ${planPrice.monthly['virtual-office-collect']} per month for our <a href="https://www.hoxtonmix.com/virtual-office-collect/">Virtual Office Collect</a> plan: dedicated mail handling, 30-day storage & Pick Up Point. ${planPrice.monthly['virtual-office-forward']} per month for UK Mail Forwarding within one business day, or ${planPrice.monthly['virtual-office-scan-pro']} per month for our <a href="https://www.hoxtonmix.com/virtual-office-scan-pro/">Virtual Office Scan Pro</a> plan: scan, email & UK Mail Forwarding within one business day.</p>
<p class="px-sm-0 px-2">For ${planPrice.monthly['virtual-office-phone']} per month, you may want to upgrade your Virtual Office package with our optional virtual phone package.</p>`,
  needAPhysicalCopy:
    'Need a physical copy of an important item like a bank card or cheque?',
  needAPhysicalCopyDescription: `<p class="mb-md-8 mb-6 px-2">
  If you need essential items like bank cards or cheques, you can pay for postal credit via the customer portal or mobile app.  
</p><p class="mb-0 px-2">
We can forward physical copies within the UK or worldwide at the cost of postage plus a 50p handling charge per letter. You can also collect them from our office within 30 working days. After 30 days, mail is securely destroyed.
</p>`,
}

export const mailHandlingStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
