import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { planPrice } from 'common/utils/planPrice'

const formattedPrices = planPrice.monthly

const defaultStrings = {
  setupCollectDescription: `<p class="text-xl mb-lg-5 mb-4 px-lg-0"><strong>
<span class="d-xxl-block"><span class="d-block d-sm-inline">The Hoxton Mix Virtual</span> <span class="d-block d-sm-inline">Office Collect gives you all the</span></span> <span class="d-xxl-block"><span class="d-block d-sm-inline">benefits of a prime London</span> business address,</span> without <span class="d-block d-sm-inline">the expensive overheads.</span>
</strong></p><p>
    Virtual Office Collect is the perfect solution for freelancers and entrepreneurs looking to establish a presence in London's dynamic technology hub. Just ${formattedPrices['virtual-office-collect']} per month + VAT for a Prime Mailing Address and dedicated mail handling service including <span class="d-xxl-block">Drop Off/Pick Up Point.</span>
</p>`,
  setupForwardDescription: `<p class="lead mb-lg-5 mb-4 px-lg-0"><strong>
<span class="d-xxl-block">The Hoxton Mix Virtual Office Forward gives you </span> <span class="d-xxl-block">all the benefits of a prime London business address,</span> without <span class="d-block d-sm-inline">the expensive overheads.</span>
</strong></p>
<p>
Virtual Office Forward is the perfect mail forwarding solution for international companies, freelancers, and entrepreneurs looking to establish a presence in London's dynamic technology hub. Just ${formattedPrices['virtual-office-forward']} per month + VAT with UK Mail Forwarding within one business day.
</p>
`,
  setupScanLiteDescription: `<p>
<strong>Scan Lite</strong> provides the perfect solution for freelancers, entrepreneurs, and SMEs looking to establish a presence in London's dynamic technology hub. It is also ideal for international customers and digital nomads.
</p>
<p>
    <strong>Just ${formattedPrices['virtual-office-scan-lite']} per month</strong> (+VAT), get a prestigious London address. Keep your personal information private and impress clients and customers.
</p>
<p>
Our dedicated team securely opens your letters, converting them into high-resolution, searchable PDFs. Scans are sent directly to your email, mobile app, and customer portal within one business day of receipt. <strong>View your postal mail from anywhere, on any device, at any time</strong>.
</p>
<p>
We store original letters for 30 days at our Pick Up Point. You can pay to have the physical copy sent to your address. After 30 days, letters are securely cross-shredded with a Data Destruction Certificate and recycled by our partner.
</p>`,
  setupScanProDescription: `<p class="mb-lg-4 mb-3 px-4">
<strong>Scan Pro</strong> is the perfect solution for freelancers, entrepreneurs, UK and International companies looking to establish a presence in London's dynamic technology hub.
</p><p class="mb-lg-4 mb-3 px-lg-4 px-2">
Just <strong>${formattedPrices['virtual-office-scan-pro']} per month</strong> (+VAT) for a Prime Mailing Address, Mail processing, Document Scan, Storage & Mail Forwarding service.
</p><p class="mb-lg-4 mb-3 px-lg-5 px-2">
Our dedicated team will open your mail securely, scan it as a PDF with full-text search and email to an address of your choosing. The hard copies can then be posted to you at no extra cost or kept in storage at our dedicated mail handling Pick Up Point.
</p><p class="mb-0 px-lg-4 px-2">
As optional security, we can then shred the original letter and delete the PDF. We will keep your scanned documents and the original email on file for 30 days in case you need the original email.
</p>`,
  setupTenMinsOrLess:
    '<span class="d-block">Set up a virtual office in</span> 10 minutes or less.',
  setupTenMinsOrLessScanLite:
    '<span class="d-block">Set up a Scan Lite plan </span>in 10-minutes or less',
  setupTenMinsOrLessScanPro:
    '<span class="d-block">Set up a Scan Pro plan </span>in 10-minutes or less',
  setupTenMinsOrLessSubtitle:
    'Our average set up time is just 10-minutes with no setup fees',
  tenMinsOrLess:
    '<span class="d-block">A virtual office</span> <span class="d-block">in 10 minutes or less.</span>',
  tenMinsOrLessDescription: `<p class="mb-4 px-sm-0 px-2">The <strong>Hoxton Mix Virtual Office London</strong> gives you all the benefits of a prime London business address from <strong>just ${planPrice.monthlyOnYearlyPlan(
    'virtual-office-collect',
  )} per month.</strong></p><p class="px-sm-0 px-2">You can specify our address as the <strong>registered address for your company</strong> with Companies House and use our Directors Service Address add-on for <strong>keeping your home address private</strong> from customers, suppliers and other third parties.</p>`,
}
export const tenMinutesOrLessStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
