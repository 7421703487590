import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { planPrice } from 'common/utils/planPrice'

const defaultStrings = {
  dedicatedDeskContent: `We have desks available on a monthly basis. The modern and contemporary top floor office at our Paul Street address was fully renovated for the Hoxton Mix and has lots of light with windows on three sides. Two glass-encased meeting rooms and two other break-out working areas. There are two toilets and a shower room with high spec modern fittings. We also have a secure, indoor bike room to store bicycles, roof terrace and BBQ area. The office is located in the heart of Old Street, dubbed the silicon roundabout, well known for being the centre of cutting-edge development in the digital sector. It’s 2 minutes walk from Old Street tube station.`,
  dedicatedDeskTitle: `Dedicated Desk (${planPrice.monthly[
    'hot-desk-coworking'
  ].replace('.00', '')} per month)`,
  plansDesksOffices: 'Plans, desks & offices',
  privateOfficeSuitsContent:
    '<p>The Hoxton Mix offers a variety of Private Office suites that are ideally suited for businesses of all sizes, ranging from 4 to 20+ employees. Private Offices are extremely competitively priced and are available on a short-term lease.</p>',
  privateOfficeSuitsTitle: 'Private Office suites',
  virtualOfficePlansContent: `<p>At Hoxton Mix, you can get started with a low-cost virtual office in London for as little as ${planPrice.monthlyOnYearlyPlan(
    'virtual-office-collect',
  )} a month <em>*</em>. With a Hoxton Mix Virtual Office, you can run your business from anywhere in the world while enjoying a prime London company address for your website, marketing materials, and more. Our secure processing facility ensures that your post is handled efficiently and reliably within one business day of receipt.</p>
<p>Our most popular plan, the <a href="/virtual-office-scan-lite/">Virtual Office Scan Lite</a>, offers a prime London business address and scanning within one business day, allowing you to receive your physical post online. We store the post for 30 days for collection or, if you require a physical copy, forward it anywhere in the UK for the cost of a 1st class stamp plus a 50p processing fee. The <a href="/virtual-office-collect/">Virtual Office Collect</a> plan includes a prime London business address, mail handling with 30 days of secure storage, and the option to pay a fee to have the post scanned or forwarded to any UK address.</p>
<p>The <a href="/virtual-office-forward/">Virtual Office Forward</a> plan offers a prime London business address and mail forwarding within one business day to anywhere in the UK. For those needing comprehensive scanning services, the <a href="/virtual-office-scan-pro/">Virtual Office Scan Pro</a> plan also offers a prime London business address, scanning of all your physical post, and forwarding it anywhere in the UK at no additional cost.</p>
<p> <em>*</em> Based on the Virtual Office Collect plan on an annual basis of ${
    planPrice.yearly['virtual-office-collect']
  } per year plus VAT, which works out at ${planPrice.monthlyOnYearlyPlan(
    'virtual-office-collect',
  )} per month plus VAT.</p>`,
  virtualOfficePlansTitle: `Virtual Office Plans (from as little as ${planPrice.monthlyOnYearlyPlan(
    'virtual-office-collect',
  )} per month)`,
}
export const plansDesksOfficesStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
