import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { Format } from 'common/utils/format'

export const promoCreditAmount = Format.money(1000).replace('.00', '')
export const companyFormationPrice = Format.money(1000).replace('.00', '')

const defaultStrings = {
  articlesOfAssociation: 'Articles of Association',
  articlesOfAssociationDescription:
    'The Articles of Association is sort of like the Terms and Conditions for your limited company. It sets out the rules that will govern the administration of your company, such as voting rights and procedure for transferring shares.',
  cardDescription:
    '<div><strong class="lead-large">When you buy a Virtual Office</strong><p class="lead px-xxl-10 px-8 mt-md-0 mt-4">The fastest, most convenient way to form your new company.</p></div>',
  cardTitle: '<span class="d-block">Company Formation</span> the easy way.',
  certificateOfIncorporation: 'Certificate of Incorporation',
  certificateOfIncorporationDescription:
    'This is the one you can frame and hang on the wall, featuring your company details and the UK Coat of Arms.',
  clickHere: 'Click here',
  combinedRegister: 'Combined Register',
  combinedRegisterDescription:
    'This document details all the individuals involved in the running of your limited company. It lists all the directors, any company secretaries, as well as details of any other company interests a director may have (for example, if a director owns shares in another limited company).',
  companyFormationDescription1: `Furthermore, if you purchase a {0}, we will give you a ${promoCreditAmount} promo credit to get you started.`,
  companyFormationDescription2: '{0} to form your new company in minutes.',
  companyFormationDescription3: `During checkout enter your email address, and we will send you a ${promoCreditAmount} promotional credit which can be used on all {0}.`,
  companyFormationDescription4: `After forming your new company {0} to a Virtual Office plan using your ${promoCreditAmount} promotional credit.`,
  companyFormationDescription5:
    'It’s quick, secure and unlike some formation company providers, staff are here to help you throughout the process.',
  companyFormationEasy: 'Company Formation & Registration just got easier!',
  companyFormationEasyDescription:
    'If you’re thinking about forming a limited company, then we’ve got great news for you! <br class="d-xxl-block d-none"/> We’ve partnered with Crunch to guide you through a <br class="d-sm-none"/> new company formation.',
  companyFormationWithCrunch: 'Company Formation with Crunch',
  companyFormationWithCrunch2: 'Crunch Company Registratiopn',
  companyFormationWithCrunchTitle: `Just ${companyFormationPrice} to form your limited company through our company formation service partner, {0}.`,
  companyRegistrationJustGotEasier:
    'Company <br/>Registration<br/> just got<br/> easier!',
  crunch: 'Crunch',
  expertCustomerSupport: 'Expert Customer Support',
  expertCustomerSupportDescription:
    'Our company formation specialists will answer any questions you have and explain anything you’re unsure of.',
  firstCompanyMinutes: 'First Company Minutes',
  firstCompanyMinutesDescription:
    'Although you probably won’t actually have a meeting (unless you enjoy that kind of thing!), the First Company Minutes detail the process of forming your limited company.',
  heroSubTitle2:
    'Maintain a presence in the UK when <br/> overseas. Digital forwarding within one business day.',
  heroSubtitle: `And get ${promoCreditAmount} off any Virtual Office plan`,
  heroTitle: `Company Formation <br/>For Just ${companyFormationPrice}`,
  memorandumOfAssociation: 'Memorandum of Association',
  memorandumOfAssociationDescription:
    'This document sets out how the company must operate, and the duties it must undertake (called the “company Objects”), such as to pay and look after any staff and to make sure the relevant insurance is taken out. You can think of it as o sort of mission statement, albeit written in boring legalese.',
  orCallNow: 'Or call us now on 0333 920 8607',
  registerLimitedCompanyToday: 'Register your limited company today',

  registerYourNewCompany: 'Register your new company',
  seoCompanyFormationDescription:
    'A premium same-day company formation service without the price.',
  seoCompanyFormationTitle: 'Free UK Company Formation | Hoxton Mix',
  shareCertificate: 'Share Certificate',
  shareCertificateDescription:
    'Rather than belonging to the company, the Share Certificate is intended for the individual shareholders. It gives details of how many shares a person owns, and how much those shares are worth.',
  signUp: 'sign up',
  virtualOfficePlan: 'Virtual Office plan',
  virtualOfficePlans: 'Virtual Office plans',
  whatDoYouGet: 'What do you get?',
  whatDoYouGetDescription: 'When registering your limited company.',
}
export const companyFormationStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
